.typing-indicator {
    display: flex;
    align-items: center;
  }
  
  .typing-indicator span {
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: #333;
    border-radius: 50%;
    display: inline-block;
    animation: typing 1s infinite ease;
  }
  
  @keyframes typing {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
  }
  